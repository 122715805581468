import video from './assets/fantasy.mp4';
import logo_bw from './assets/logo_bw.png';
import logo_cl from './assets/logo_cl.png';
import profile_pic from './assets/sh.jpg';
import chain_gr from './assets/chain-gr-20.png';
import giticon from './assets/giticon.png';
import likedin from './assets/likedin.png';
import menu from './assets/hambuger.png'
import x from './assets/x.png'
import sass from './assets/sass.png';
import javascript from './assets/javascript.png';
import react from './assets/react.png';
import nodeexpress from './assets/nodeexpress.png';
import mongodb from './assets/mongodb.png';
import arrow from './assets/arrow.png'
import Typed from "react-typed";
import './App.scss';
import { useState } from 'react';
// COMPONENTS
function Navigation(){
  const [show, setShow] = useState(menu)
  const [expand, setExpand] = useState('')
  const showing = () => {
    setShow(x)
    setExpand('expand')
  }
  const closing = () => {
    setShow(menu)
    setExpand('')
  }
  return <nav className='navigation'>
  <ul>
    <li className='title'>
      <a href="/" className='title'>
        <img src={logo_cl} className="logoImg"></img><span>Sunghyun Park</span>
      </a>
      <img src={show}
      onClick={()=>{(show == menu)?showing():closing()}}
      className="hambuger"
      alt="menu icon" />
    </li>
    <li className={expand}><a href="#about">About</a></li>
    <li className={expand}><a href="#technologies">Technologies</a></li>
    <li className={expand}><a href="#projects">Projects</a></li>
    <li className={expand}><a href="https://drive.google.com/file/d/14g2ypEpgkcBBOXomyecfMLo7bmOpugbT/view?usp=sharing">Resum&#233;</a></li>
  </ul>
</nav>
} 
function Topsection(){
  return <div className='topSection'>
  <div className='videoWrapper'>
    <video src={video} autoPlay loop playsInline muted={true} className='videoTop'></video>
  </div>
  <div className='topText'>
    <img src={logo_cl}></img>
    <h1>Sunghyun Park</h1>
    <h2>
      <Typed
      strings={[
            "I'm a full-stack engineer",
            "And UI/UX Designer as well !"
          ]}
          typeSpeed={110}
          backSpeed={50}
          loop
        />
        </h2>
    <h3>Versatile Web Specialist</h3>
    {/* https://gurtn.tistory.com/168 */}
    <a className='button' href="mailto:olivesunghyun@gmail.com">
      <span>Contact &#x3e;</span>
    </a>
  </div>
</div>
}
function About(){
  return <div id='about'>
  <article className='aboutArea'>
    <img src={profile_pic}></img>
    <div className='aboutText'>
      <h2>Hello! I am Sunghyun Park,<br/> also called Collin!</h2>
      <p>As a <b>web developer and UI/UX designer</b>, I am an upstander to solve challenging problems, which brings unique value to the user experience via a web application.</p>
      <p>Starting from my diploma in <b>NMPD</b> {'('}New Media Production & Design{')'} from Southern Alberta Institute of Technology in Calgary, I have been improving both my web development and UI/UX skills.</p>
      <p>I am a dedicated team player and open-minded toward learning new technologies from anyone as an active learner. Exploring new knowledge of development makes my heart quicken.</p>
    </div>
  </article>
</div>
}
function Technologies(){
  return <div id="technologies">
  <div className='techWrapper'>
    <h2 className='h2Title'>Technologies</h2>
    <p>that I've been mainly working with recently</p>
    <ul className='techStack'>
      <li><img className="techIcons" src={sass}></img></li>
      <li><img className="techIcons" src={javascript}></img></li>
      <li><img className="techIcons" src={react}></img></li>
      <li><img className="techIcons" src={nodeexpress}></img></li>
      <li><img className="techIcons" src={mongodb}></img></li>
    </ul>
  </div>
  <section>
    <article>
      <h3>Front-end</h3>
      <div className='techList'>
        <span>#HTML5</span><span>#CSS3</span><span>#SASS</span><span>#JS</span><span>#REACT</span>
      </div>
    </article>
    <article>
      <h3>Back-end</h3>
      <div className='techList'>
        <span>#Node.js</span><span>#Express.js</span><span>#PHP</span><span>#MySQL</span><span>#MongoDB</span><span>#OAuth</span>
      </div>
    </article>
    <article>
      <h3>Design</h3>
      <div className='techList'>
        <span>#PS</span><span>#AI</span><span>#PR</span><span>#AE</span><span>#Adobe XD</span>
      </div>
    </article>
  </section>
</div>
}
function Contact(){
  return <div id="contact">
  <article>
    <h2 className='h2Title contactTitle'>Contact</h2>
    <p>Please contact me at <a href="mailto:oliveguy85@gmail.com"><b>olivesunghyun@gmail.com</b></a></p>
    <p>and find me via following links as well.</p>
    <div className='links'>
      <a href="https://github.com/oliveguy"><img src={giticon}/></a>
      <a href="https://www.linkedin.com/in/sunghyun-park-a29438243"><img src={likedin}/></a>
    </div>
  </article>
</div>
}
function Footer(){
  return <footer>
  <ul>
    <li><b>SUNGHYUN PARK</b></li>
    <li className='copyright'>Copyright © 2023, Sunghyun Park. All Rights Reserved.</li>
    <li>
      <a href="https://github.com/oliveguy"><img src={giticon}/></a>
      <a href="https://www.linkedin.com/in/sunghyun-park-a29438243"><img src={likedin}/></a>
    </li>
  </ul>
</footer>
}
function Detail(props){
  return (
    (props.detail == "")?"":
    <div className="detail">
      <img src={`/img/${props.detail.img}.jpg`} alt={props.detail.title} />
      <div className='detailText'>
        <h3>{props.detail.title}</h3>
        <p>{props.detail.desc}</p>
        <br />
        <h4>Feature</h4>
        <p>{props.detail.feature}</p>
        <br />
        <a href={props.detail.url}>Go to {props.detail.title}</a>
      </div>
    </div>
  )
}
// THE APP
function App() {
  const [detail, Setdetail] = useState('');
  const webItems = [
    {
      id:0,
      title:'Convertrix',
      brief:'A versatile unit converter',
      desc:'Convert various units of measurement quickly and accurately',
      img:'convertrix',
      tech:['React','SASS'],
      url:'http://devsunghyun.com/convertrix-deploy/',
      git:'https://github.com/oliveguy/Convertrix',
      feature:'Convert various units such as length, mass, area, and volume built with React.js'
    },
    {
      id:1,
      title:'PetLink',
      brief:'Pet dating web-application',
      desc:'Mobile-first designed web-app for pet dating',
      img:'petlink',
      tech:['Express.js','MongoDB','React','SASS'],
      url:'https://formal-station-384513.uw.r.appspot.com/',
      git:'https://github.com/oliveguy/PetLink',
      feature:'Login / Sigup and live chatting functionalities with mobile first design'
    },
    {
      id:2,
      title:'TACA',
      brief:'WordPress website for Tick Awareness Canada Association',
      desc:'Tick Awareness Canada Association website which is Canadian non-profit dedicated to universal, proactive tick safety and education',
      img:'TACA',
      tech:['WordPress','PHP','SASS'],
      url:'https://tickawarenesscanada.net/',
      git:'https://github.com/oliveguy/TACA',
      feature:'Donation fuction / eCommerce / Responsice design'
    },
    {
      id:3,
      title:'TalkieTalk',
      brief:'Real time chating app',
      desc:'Chating app with signup & login function',
      img:'talkitalk',
      tech:['Express.js','MongoDB','React','SASS'],
      url:'https://talkietalk-front.uw.r.appspot.com/',
      git:'https://github.com/oliveguy/live-chat-app',
      feature:'User can talk with other users in real-time with login and sigup function'
    },
    {
      id:4,
      title:'Rest Country API',
      brief:'Interactive coutry Info. app',
      desc:'Interactive coutry app to view each information using APIs',
      img:'restapicountry',
      tech:['SASS','React','API'],
      url:'http://devsunghyun.com/country-api-app-deploy/',
      git:'https://github.com/oliveguy/REST-Countries-API',
      feature: 'Interative web application which user can choose the country to view detailed information as well as sorting function'
    },
    {
      id:5,
      title:'Dev.Talk',
      brief:'Interactive Comment App',
      desc:'Blog comment app to add post, reply, andlike',
      img:'devtalk',
      tech:['SASS','React'],
      url:'http://devsunghyun.com/comment_app_deploy/',
      git:'https://github.com/oliveguy/interative-comment-app',
      feature: 'Posting and Commenting web application built with React.js and Restfull APIs.'
    },
    {
      id:6,
      title:'Tremolo',
      brief:'e-learning website',
      desc:'e-learning website for very beginners to learn how to play the guitar',
      img:'tremolo',
      tech:['SASS','JS','Express.js','MongoDB'],
      url:'https://tremolo-382318.wl.r.appspot.com/',
      git:'https://github.com/oliveguy/tremolo',
      feature: 'Login / Signup with bcrypt library & user information can be checked in user account panel. Checking course progress in account page and course pages / Auto pause function in video to secure practice time'
    },
    {
      id:7,
      title:'Fish Creek Prov. Park',
      brief:'Interactive map',
      desc:'Interactive map for Fish Creek Park in Calgary, AB',
      img:'fishcreek',
      tech:['JS','PHP','MySQL'],
      url:'http://dev.saitnewmedia.ca/~spark/fishcreek/',
      git:'https://github.com/oliveguy/fish-creek',
      feature: 'The map provides you with ample information on interesting points of Fish Creek Park by mythical creatures. Users can see each intriguing points of interest in Canada\'s second largest urban park. and post thier reviews without refreshing the page (AJAX).'
    },
    {
      id:8,
      title:'Coco Bakery',
      brief:'e-Commerce',
      desc:'e-Commerce website for selling bread and sweat treats to customers',
      img:'coco',
      tech:['JS','PHP','MySQL'],
      url:'http://dev.saitnewmedia.ca/~spark/mmda225/final/',
      git:'https://github.com/oliveguy/e-commerce',
      feature:'Signup and login functions / Detailed information pages depending on item / Addtional Cart functions such as removing items and selecting the quantity.'
    },
    {
      id:9,
      title:'Infinity & Beyond',
      brief:'2022 NASA International Space Apps Challenge',
      desc:'Website for displaying current aurora status',
      img:'iab',
      tech:['JS','PHP','MySQL'],
      url:'https://www.infinityandbeyond.club/',
      git:'https://github.com/hejkeikei/infinity-and-beyond',
      feature: 'User can see aurora forecast and adjust intensity to find aurora status'
    },
    {
      id:10,
      title:'Porfolio',
      brief:'My portfolio website',
      desc:'Portfolio website to introduce Sunghyun',
      img:'portfolio',
      tech:['SASS','JS','REACT'],
      url:'https://devsunghyun.com/',
      git:'https://github.com/oliveguy/portfolio',
      feature:'Easy contact and simple UI & UX'
    },
    {
      id:11,
      title:'NMPD Promotion',
      brief:'NMPD Program website',
      desc:'This website is to introduce NMPD',
      img:'nmpd',
      tech:['HTML','Pure CSS','JS'],
      url:'http://dev.saitnewmedia.ca/~spark/NMPD_promotion/',
      git:'https://github.com/oliveguy/portfolio',
      feature:'Intro website for NMPD(New Media Production and Design) with interactive videos'
    }
  ]
  const uiuxItems = [
    {
      id:0,
      title:'Grammy\'s',
      img:'grammy',
      url:'https://xd.adobe.com/view/5089acd2-d3b5-4ccd-b26a-bbcb584f5df6-05da/grid'
    },
    {
      id:1,
      title:'Tipplo',
      img:'tipplo',
      url:'http://dev.saitnewmedia.ca/~spark/mmda324/calculator/'
    }
  ]
  const [transform, SetTransform] = useState(0);
  return (
    <div className="App">
      <Navigation></Navigation>
      <Topsection></Topsection>
      <About></About>
      <Technologies></Technologies>
      <div id='projects'>
        <div className='projectText'>
          <h2 className='h2Title'>Projects</h2>
          <p>which I participated in and contributed to</p>
        </div>
        <section className='webDev'>
          <h3 className='projectSubTitle'>Web Development</h3>
          <ul>
            {webItems.map((items,i)=>{
              return <li key={items.id} style={{transform: `translateX(${transform}px)`,transition:'.25s ease-in-out'}}>
                <h4>{items.title}</h4>
                <img
                  onClick={()=>{Setdetail(webItems[i])}}
                  src={require(`./assets/${items.img}.jpg`)}
                  className="webProjectImg"
                />
                <div className='webProjectText'>
                  <p>{items.brief}</p>
                  <p className='desc'>{items.desc}</p>
                  <p>{items.tech.map((techItem,i)=>{return <span key={i}>#{techItem}</span>})}</p>
                </div>
                <div className='projectLink'>
                  <a href={items.url} target='_blank'><img src={chain_gr}></img></a>
                  <a href={items.git} target='_blank'><img src={giticon}></img></a>
                </div>
              </li>
            }
            )}
          {/* https://blog.naver.com/wormsbrother/222769963949 */}
          </ul>
          <div className='arrows'>
            <span><img src={arrow} onClick={()=>{(transform === 0)?SetTransform(-2240):SetTransform(transform+320)}} alt="arrow" /></span>
            <span><img src={arrow} onClick={()=>{(transform === -2240)?SetTransform(0):SetTransform(transform-320)}} alt="arrow" /></span>
          </div>
        </section>
        <Detail detail={detail}></Detail>
        <section className='uiux'>
        <h3 className='projectSubTitle'>UI/UX</h3>
          <ul>
            {uiuxItems.map((uiuxItem,i)=>{
              return <li key={i}>
              <a href={uiuxItem.url} target='_blank'><img src={require(`./assets/${uiuxItem.img}.jpg`)} className="uiuxview"/></a>
              {/* <img src={require(`./assets/blank.jpg`)}/> */}
              <a href={uiuxItem.url} target='_blank'>Learn more</a>
              {/* <a href={uiuxItem.url} target='_blank'>Learn more<img src={chain_gr}></img></a> */}
              </li>
              })
            }

          </ul>
        </section>
      </div>
      <Contact></Contact>
      <Footer></Footer>
      
    </div>
  );
}

export default App;
